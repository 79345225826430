import React from 'react';
import Layout from '../containers/layout';
import SEO from '../components/seo';
import OurGames from '../containers/ourgames';

type OurGamesPageProps = {};

const OurGamesPage: React.FunctionComponent<OurGamesPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Our Games"
        description="StoryHub is a beautiful Gatsby Blog theme designed to showcase your work in style. Perfect for designers, artists, photographers and developers to use for their portfolio website."
      />

      <OurGames/>
    </Layout>
  );
};

export default OurGamesPage;
