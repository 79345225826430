import * as React from 'react';
import { Link } from 'gatsby';
import { CardCTA, CardDetails, CardImage, CardWrapper, CardImageWrapper } from './card.style';
import { SiNintendoswitch, SiSteam, SiXbox } from 'react-icons/si';

interface CardProps {
  image?: any;
  title: string;
  excerpt: string;
  url: string;
  date?: string;
  tags?: [];
  className?: string;
  readTime?: string;
  callToAction?: string;
  isStatic?: boolean;
  switchUrl?: string;
  xboxUrl?: string;
  steamUrl?: string;
  additionalLinksColour?: string;
}

const Card: React.FunctionComponent<CardProps> = ({
  image,
  title,
  excerpt,
  url,
  callToAction,
  switchUrl,
  xboxUrl,
  steamUrl,
  additionalLinksColour,
}) => {
  return (
    <CardWrapper>
      <Link to={url} target="_blank">
        <CardImageWrapper>
          <CardImage src={image} alt={title} className='card-image'/>
          <span className={steamUrl || switchUrl || xboxUrl ? 'additional-links' : 'additional-links additional-links-hidden'}>
            {steamUrl && (
                <a href={steamUrl} className="additional-link" target="_blank">
                  <SiSteam color={additionalLinksColour || '#dbdbdb'}/>
                </a>
            )}
            {switchUrl && (
                <a href={switchUrl} className="additional-link" target="_blank">
                  <SiNintendoswitch color={additionalLinksColour || '#dbdbdb'}/>
                </a>
            )}
            {xboxUrl && (
                <a href={xboxUrl} className="additional-link" target="_blank">
                  <SiXbox color={additionalLinksColour || '#dbdbdb'}/>
                </a>
            )}
          </span>
        </CardImageWrapper>
        <CardDetails>
          {excerpt}
          <CardCTA>
            <Link to={url} target="_blank">
              {callToAction || "Read more"} 
            </Link>
          </CardCTA>
        </CardDetails>
      </Link>
    </CardWrapper>
  )
}

export default Card;