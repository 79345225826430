import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  position: relative;
  .additional-links {
    position: absolute;
    right: 4px;
    top: 4px;
    background: #010000e6;
    padding: 2px 6px;
    border-radius: 3px;
  }
  .additional-links-hidden {
    display: none;
  }
  .additional-link {
    margin-left: 12px;
    &:first-child {
      margin-left: 0;
    }
    svg {
      width: 18px;
      height: 18px;
      position: relative;
      top: 2px;
    }
  }
  &:hover {
    .card-image {
      transform: scale(1.05);
    }
  }
`;

export const CardImage = styled.img`
  width: 100%;
  transition: 0.25s ease-in-out;
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CardDetails = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${themeGet('colors.primaryCardDetailText', '#292929')};
  line-height: 2;
  text-align: center;
  background-color: ${themeGet('colors.primaryCardDetail', '#292929')};
  padding: 12px 24px 12px;
  margin-top: -4px;
  position: relative;
  z-index: 9998;
`;

export const CardCTA = styled.div`
  display: none;
  a {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
    color: ${themeGet('colors.textColor', '#292929')};
    background-color: ${themeGet('colors.white', '#ffffff')};
    text-transform: uppercase;
    padding: 13px 30px;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    transition: 0.15s ease-in-out;
    @media (max-width: 990px) {
      font-size: 14px;
    }
    &:hover {
      background-color: ${themeGet('colors.textColor', '#292929')};
      color: ${themeGet('colors.white', '#ffffff')};
      text-decoration: none;
    }
  }
`;